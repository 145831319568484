"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardRenderItemType = void 0;
var CardRenderItemType;
(function (CardRenderItemType) {
    CardRenderItemType["TEMPERATURE"] = "temperature";
    CardRenderItemType["OXYGEN"] = "oxygen";
    CardRenderItemType["OCEANS"] = "oceans";
    CardRenderItemType["VENUS"] = "venus";
    CardRenderItemType["PLANTS"] = "plants";
    CardRenderItemType["TR"] = "tr";
    CardRenderItemType["HEAT"] = "heat";
    CardRenderItemType["ENERGY"] = "energy";
    CardRenderItemType["TITANIUM"] = "titanium";
    CardRenderItemType["STEEL"] = "steel";
    CardRenderItemType["MEGACREDITS"] = "megacredits";
    CardRenderItemType["CARDS"] = "cards";
    CardRenderItemType["FLOATERS"] = "floaters";
    CardRenderItemType["ASTEROIDS"] = "asteroids";
    CardRenderItemType["MICROBES"] = "microbes";
    CardRenderItemType["ANIMALS"] = "animals";
    CardRenderItemType["EVENT"] = "event";
    CardRenderItemType["SPACE"] = "space";
    CardRenderItemType["EARTH"] = "earth";
    CardRenderItemType["JOVIAN"] = "jovian";
    CardRenderItemType["BUILDING"] = "building";
    CardRenderItemType["COLONIES"] = "colonies";
    CardRenderItemType["SCIENCE"] = "science";
    CardRenderItemType["TRADE"] = "trade";
    CardRenderItemType["TRADE_DISCOUNT"] = "trade_discount";
    CardRenderItemType["TRADE_FLEET"] = "trade_fleet";
    CardRenderItemType["COLONY_TILE"] = "colony_tile";
    CardRenderItemType["CHAIRMAN"] = "chairman";
    CardRenderItemType["PARTY_LEADERS"] = "party_leaders";
    CardRenderItemType["DELEGATES"] = "delegates";
    CardRenderItemType["INFLUENCE"] = "influence";
    CardRenderItemType["NO_TAGS"] = "no_tags";
    CardRenderItemType["EMPTY_TAG"] = "empty_tag";
    CardRenderItemType["PRESERVATION"] = "preservation";
    CardRenderItemType["WILD"] = "wild";
    CardRenderItemType["FIGHTER"] = "fighter";
    CardRenderItemType["CAMPS"] = "camps";
    CardRenderItemType["DIVERSE_TAG"] = "diverse_tag";
    CardRenderItemType["CITY"] = "city";
    CardRenderItemType["GREENERY"] = "greenery";
    CardRenderItemType["PLATE"] = "plate";
    CardRenderItemType["TEXT"] = "text";
    CardRenderItemType["NBSP"] = "nbsp";
    CardRenderItemType["EMPTY_TILE"] = "empty_tile";
    CardRenderItemType["EMPTY_TILE_GOLDEN"] = "empty_tile_golden";
    CardRenderItemType["EMPTY_TILE_SPECIAL"] = "empty_tile_special";
    CardRenderItemType["SELF_REPLICATING"] = "self_replicating";
    CardRenderItemType["MULTIPLIER_WHITE"] = "multiplier_white";
    CardRenderItemType["IGNORE_GLOBAL_REQUIREMENTS"] = "ignore_global_requirements";
    CardRenderItemType["PRELUDE"] = "prelude";
    CardRenderItemType["AWARD"] = "award";
    CardRenderItemType["MILESTONE"] = "milestone";
    CardRenderItemType["CORPORATION"] = "corporation";
    CardRenderItemType["VP"] = "vp";
    CardRenderItemType["COMMUNITY"] = "community";
    CardRenderItemType["DISEASE"] = "disease";
    CardRenderItemType["MOON"] = "moon";
    CardRenderItemType["RESOURCE_CUBE"] = "resource_cube";
    CardRenderItemType["DATA_RESOURCE"] = "data resource";
    CardRenderItemType["MOON_HABITAT"] = "moon-colony";
    CardRenderItemType["MOON_HABITAT_RATE"] = "moon-colony-rate";
    CardRenderItemType["MOON_ROAD"] = "moon-road";
    CardRenderItemType["MOON_LOGISTICS_RATE"] = "moon-logistics-rate";
    CardRenderItemType["MOON_MINE"] = "moon-mine";
    CardRenderItemType["MOON_MINING_RATE"] = "moon-mine-rate";
    CardRenderItemType["SYNDICATE_FLEET"] = "syndicate-fleet";
    CardRenderItemType["MARS"] = "mars";
    CardRenderItemType["VENUSIAN_HABITAT"] = "venusian-habitat";
    CardRenderItemType["SPECIALIZED_ROBOT"] = "specialized-robot";
    CardRenderItemType["AGENDA"] = "mind-set-agenda";
    CardRenderItemType["PLANETARY_TRACK"] = "plantary-track";
    CardRenderItemType["SEED"] = "seed";
    CardRenderItemType["ORBITAL"] = "orbital";
    CardRenderItemType["GLOBAL_EVENT"] = "global-event";
    CardRenderItemType["FIRST_PLAYER"] = "1p";
    CardRenderItemType["RULING_PARTY"] = "ruling-party";
    CardRenderItemType["CATHEDRAL"] = "cathedral";
    CardRenderItemType["GRAPHENE"] = "graphene";
    CardRenderItemType["NOMADS"] = "nomads";
    CardRenderItemType["HYDROELECTRIC_RESOURCE"] = "hydroelectric-resource";
    CardRenderItemType["CITY_OR_SPECIAL_TILE"] = "city-or-special-tile";
    CardRenderItemType["ARROW_OPG"] = "arrow-opg";
    CardRenderItemType["REDS"] = "reds";
    CardRenderItemType["REDS_DEACTIVATED"] = "reds-deactivated";
    CardRenderItemType["HAZARD_TILE"] = "hazard-tile";
    CardRenderItemType["ADJACENCY_BONUS"] = "adjacency-bonus";
    CardRenderItemType["CLONE_TROOPER"] = "clone-trooper";
    CardRenderItemType["IDENTIFY"] = "identify";
    CardRenderItemType["EXCAVATE"] = "excavate";
    CardRenderItemType["CORRUPTION"] = "corruption";
    CardRenderItemType["NEUTRAL_DELEGATE"] = "neutral-delegate";
    CardRenderItemType["UNDERGROUND_RESOURCES"] = "underground-resources";
    CardRenderItemType["CORRUPTION_SHIELD"] = "corruptive-block";
    CardRenderItemType["TOOL"] = "tool";
    CardRenderItemType["WARE"] = "ware";
    CardRenderItemType["SCOOP"] = "scoop";
    CardRenderItemType["JOURNALISM"] = "journalism";
    CardRenderItemType["ACTIVIST"] = "activist";
    CardRenderItemType["SUPPLY_CHAIN"] = "supply-chain";
    CardRenderItemType["GEOSCAN_ICON"] = "geoscan-icon";
    CardRenderItemType["UNDERGROUND_SHELTERS"] = "underground-shelters";
})(CardRenderItemType = exports.CardRenderItemType || (exports.CardRenderItemType = {}));
