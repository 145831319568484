"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const AppButton_vue_1 = require("@/client/components/common/AppButton.vue");
const PreferencesManager_1 = require("@/client/utils/PreferencesManager");
let unique = 0;
exports.default = vue_1.default.extend({
    name: 'or-options',
    props: {
        playerView: {
            type: Object,
        },
        players: {
            type: Array,
        },
        playerinput: {
            type: Object,
        },
        onsave: {
            type: Function,
        },
        showsave: {
            type: Boolean,
        },
        showtitle: {
            type: Boolean,
        },
    },
    components: {
        AppButton: AppButton_vue_1.default,
    },
    data() {
        if (this.playerinput.options === undefined) {
            throw new Error('no options provided for OrOptions');
        }
        const displayedOptions = this.playerinput.options.filter((option) => {
            if (option.type !== 'card') {
                return true;
            }
            if (option.showOnlyInLearnerMode === false) {
                return true;
            }
            return (0, PreferencesManager_1.getPreferences)().learner_mode;
        });
        const initialIdx = this.playerinput.initialIdx ?? 0;
        let selectedOption = displayedOptions[initialIdx];
        if (displayedOptions.length > 1 &&
            selectedOption.type === 'card' &&
            !selectedOption.cards.some((card) => card.isDisabled !== true)) {
            selectedOption = displayedOptions[initialIdx + 1];
        }
        return {
            displayedOptions,
            radioElementName: 'selectOption' + unique++,
            selectedOption,
        };
    },
    methods: {
        playerFactorySaved() {
            const idx = this.playerinput.options.indexOf(this.selectedOption);
            if (idx === undefined || idx === -1) {
                throw new Error('option not found');
            }
            return (out) => {
                this.onsave({
                    type: 'or',
                    index: idx,
                    response: out,
                });
            };
        },
        saveData() {
            let ref = this.$refs['inputfactory'];
            if (Array.isArray(ref)) {
                ref = ref[0];
            }
            ref.saveData();
        },
    },
});
