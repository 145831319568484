"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const AppButton_vue_1 = require("@/client/components/common/AppButton.vue");
const WarningsComponent_vue_1 = require("@/client/components/WarningsComponent.vue");
const Color_1 = require("@/common/Color");
const CardOrderStorage_1 = require("@/client/utils/CardOrderStorage");
const Card_vue_1 = require("@/client/components/card/Card.vue");
const ActiveCardsSortingOrder_1 = require("@/client/utils/ActiveCardsSortingOrder");
exports.default = vue_1.default.extend({
    name: 'SelectCard',
    props: {
        playerView: {
            type: Object,
        },
        playerinput: {
            type: Object,
        },
        onsave: {
            type: Function,
        },
        showsave: {
            type: Boolean,
            required: false,
            default: false,
        },
        showtitle: {
            type: Boolean,
        },
    },
    data() {
        return {
            cards: [],
            warning: undefined,
            owners: new Map(),
            warnings: undefined,
        };
    },
    components: {
        Card: Card_vue_1.default,
        WarningsComponent: WarningsComponent_vue_1.default,
        AppButton: AppButton_vue_1.default,
    },
    watch: {
        cards() {
            this.$emit('cardschanged', this.getData());
        },
    },
    methods: {
        cardsSelected() {
            if (Array.isArray(this.cards)) {
                return this.cards.length;
            }
            else if (this.cards === false || this.cards === undefined) {
                return 0;
            }
            return 1;
        },
        getOrderedCards() {
            let cards = [];
            if (this.playerinput.cards !== undefined) {
                if (this.playerinput.selectBlueCardAction) {
                    cards = (0, ActiveCardsSortingOrder_1.sortActiveCards)(this.playerinput.cards);
                }
                else {
                    cards = CardOrderStorage_1.CardOrderStorage.getOrdered(CardOrderStorage_1.CardOrderStorage.getCardOrder(this.playerView.id), this.playerinput.cards);
                }
            }
            if (this.playerinput.showOwner) {
                this.owners.clear();
                this.playerinput.cards.forEach((card) => {
                    const owner = this.findOwner(card);
                    if (owner !== undefined)
                        this.owners.set(card.name, owner);
                });
            }
            return cards;
        },
        hasCardWarning() {
            if (Array.isArray(this.cards)) {
                return false;
            }
            else if (typeof this.cards === 'object') {
                this.warnings = this.cards.warnings;
                if (this.cards.warning !== undefined) {
                    this.warning = this.cards.warning;
                    return true;
                }
            }
            return false;
        },
        getData() {
            return Array.isArray(this.$data.cards) ? this.$data.cards.map((card) => card.name) : [this.$data.cards.name];
        },
        saveData() {
            this.onsave({ type: 'card', cards: this.getData() });
        },
        getCardBoxClass(card) {
            if (this.playerinput.showOwner && this.getOwner(card) !== undefined) {
                return 'cardbox cardbox-with-owner-label';
            }
            return 'cardbox';
        },
        findOwner(card) {
            for (const player of this.playerView.players) {
                if (player.tableau.find((c) => c.name === card.name)) {
                    return { name: player.name, color: player.color };
                }
            }
            return undefined;
        },
        getOwner(card) {
            return this.owners.get(card.name) ?? { name: 'unknown', color: Color_1.Color.NEUTRAL };
        },
        isCardActivated(card) {
            return this.playerView.thisPlayer.actionsThisGeneration.includes(card.name);
        },
        buttonLabel() {
            return this.selectOnlyOneCard ? this.playerinput.buttonLabel : this.playerinput.buttonLabel + ' ' + this.cardsSelected();
        },
        robotCard(card) {
            return this.playerView.thisPlayer.selfReplicatingRobotsCards?.find((r) => r.name === card.name);
        },
    },
    computed: {
        selectOnlyOneCard() {
            return this.playerinput.max === 1 && this.playerinput.min === 1;
        },
        isOptionalToManyCards() {
            return this.playerinput.max !== undefined &&
                this.playerinput.max > 1 &&
                this.playerinput.min === 0;
        },
    },
});
