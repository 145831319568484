"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const paths_1 = require("@/common/app/paths");
const statusCode_1 = require("@/common/http/statusCode");
const LogMessageDataType_1 = require("@/common/logs/LogMessageDataType");
const Card_vue_1 = require("@/client/components/card/Card.vue");
const utils_1 = require("@/common/utils/utils");
const SoundManager_1 = require("@/client/utils/SoundManager");
const PreferencesManager_1 = require("@/client/utils/PreferencesManager");
const GlobalEvent_vue_1 = require("@/client/components/turmoil/GlobalEvent.vue");
const AppButton_vue_1 = require("@/client/components/common/AppButton.vue");
const LogMessageComponent_vue_1 = require("@/client/components/LogMessageComponent.vue");
const Colony_vue_1 = require("@/client/components/colonies/Colony.vue");
let logRequest;
class ToggleSet {
    constructor() {
        this.elements = [];
    }
    toggle(item) {
        const index = this.elements.indexOf(item);
        if (index === -1) {
            this.elements.push(item);
        }
        else {
            this.elements.splice(index, 1);
        }
    }
    get length() {
        return this.elements.length;
    }
    clear() {
        return this.elements = [];
    }
}
exports.default = vue_1.default.extend({
    name: 'log-panel',
    props: {
        id: {
            type: String,
        },
        generation: {
            type: Number,
        },
        lastSoloGeneration: {
            type: Number,
        },
        players: {
            type: Array,
        },
        color: {
            type: String,
        },
        step: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    data() {
        return {
            cardNames: new ToggleSet(),
            globalEventNames: new ToggleSet(),
            colonyNames: new ToggleSet(),
            messages: [],
            selectedGeneration: this.generation,
        };
    },
    components: {
        AppButton: AppButton_vue_1.default,
        Card: Card_vue_1.default,
        Colony: Colony_vue_1.default,
        GlobalEvent: GlobalEvent_vue_1.default,
        LogMessageComponent: LogMessageComponent_vue_1.default,
    },
    methods: {
        scrollToEnd() {
            const scrollablePanel = document.getElementById('logpanel-scrollable');
            if (scrollablePanel !== null) {
                scrollablePanel.scrollTop = scrollablePanel.scrollHeight;
            }
        },
        messageClicked(message) {
            const datas = message.data;
            datas.forEach((data) => {
                if (data.value === undefined) {
                    return;
                }
                if (data.type === LogMessageDataType_1.LogMessageDataType.CARD) {
                    this.cardNames.toggle(data.value);
                }
                else if (data.type === LogMessageDataType_1.LogMessageDataType.GLOBAL_EVENT) {
                    this.globalEventNames.toggle(data.value);
                }
                else if (data.type === LogMessageDataType_1.LogMessageDataType.COLONY) {
                    this.colonyNames.toggle(data.value);
                }
            });
        },
        hideMe() {
            this.cardNames.clear();
            this.globalEventNames.clear();
            this.colonyNames.clear();
        },
        selectGeneration(gen) {
            if (gen !== this.selectedGeneration) {
                this.getLogsForGeneration(gen);
            }
            this.selectedGeneration = gen;
        },
        getLogsForGeneration(generation) {
            const messages = this.messages;
            if (logRequest !== undefined) {
                logRequest.abort();
                logRequest = undefined;
            }
            const xhr = new XMLHttpRequest();
            logRequest = xhr;
            xhr.open('GET', `${paths_1.paths.API_GAME_LOGS}?id=${this.id}&generation=${generation}`);
            xhr.onerror = () => {
                console.error('error updating messages, unable to reach server');
            };
            xhr.onload = () => {
                if (xhr.status === statusCode_1.statusCode.ok) {
                    messages.splice(0, messages.length);
                    messages.push(...xhr.response);
                    if ((0, PreferencesManager_1.getPreferences)().enable_sounds && window.location.search.includes('experimental=1')) {
                        SoundManager_1.SoundManager.newLog();
                    }
                    if (generation === this.generation) {
                        this.$nextTick(this.scrollToEnd);
                    }
                }
                else {
                    console.error(`error updating messages, response code ${xhr.status}`);
                }
            };
            xhr.responseType = 'json';
            xhr.send();
        },
        getClassesGenIndicator(gen) {
            const classes = ['log-gen-indicator'];
            if (gen === this.selectedGeneration) {
                classes.push('log-gen-indicator--selected');
            }
            return classes.join(' ');
        },
        getGenerationsRange() {
            const generations = [];
            for (let i = 1; i <= this.generation; i++) {
                generations.push(i);
            }
            return generations;
        },
        getTitleClasses() {
            const classes = ['log-title'];
            classes.push((0, utils_1.playerColorClass)(this.color.toLowerCase(), 'shadow'));
            return classes.join(' ');
        },
        lastGenerationClass() {
            return this.lastSoloGeneration === this.generation ? 'last-generation blink-animation' : '';
        },
        getColony(colonyName) {
            return {
                colonies: [],
                isActive: false,
                name: colonyName,
                trackPosition: 0,
                visitor: undefined,
            };
        },
        isSelfReplicatingRobotsCard(cardName) {
            for (const player of this.players) {
                if (player.selfReplicatingRobotsCards.some((card) => card.name === cardName)) {
                    return true;
                }
            }
            return false;
        },
        getResourcesOnCard(cardName) {
            for (const player of this.players) {
                const playedCard = player.tableau.find((card) => card.name === cardName);
                if (playedCard !== undefined) {
                    return playedCard.resources;
                }
                const srrCard = player.selfReplicatingRobotsCards.find((card) => card.name === cardName);
                if (srrCard !== undefined) {
                    return srrCard.resources;
                }
            }
            return undefined;
        },
    },
    mounted() {
        this.getLogsForGeneration(this.generation);
    },
});
