"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const constants = require("@/common/constants");
const paths_1 = require("@/common/app/paths");
const Board_vue_1 = require("@/client/components/Board.vue");
const MoonBoard_vue_1 = require("@/client/components/moon/MoonBoard.vue");
const PlanetaryTracks_vue_1 = require("@/client/components/pathfinders/PlanetaryTracks.vue");
const LogPanel_vue_1 = require("@/client/components/LogPanel.vue");
const AppButton_vue_1 = require("@/client/components/common/AppButton.vue");
const VictoryPointChart_vue_1 = require("@/client/components/gameend/VictoryPointChart.vue");
const utils_1 = require("@/common/utils/utils");
const Timer_1 = require("@/common/Timer");
const Color_1 = require("@/common/Color");
const CardType_1 = require("@/common/cards/CardType");
const ClientCardManifest_1 = require("@/client/cards/ClientCardManifest");
const GlobalParameter_1 = require("@/common/GlobalParameter");
function getViewModel(playerView, spectator) {
    if (playerView !== undefined)
        return playerView;
    if (spectator !== undefined)
        return spectator;
    throw new Error('Neither playerView nor spectator are defined');
}
exports.default = vue_1.default.extend({
    name: 'game-end',
    props: {
        playerView: {
            type: Object,
        },
        spectator: {
            type: Object,
        },
    },
    computed: {
        viewModel() {
            return getViewModel(this.playerView, this.spectator);
        },
        game() {
            return getViewModel(this.playerView, this.spectator).game;
        },
        players() {
            return getViewModel(this.playerView, this.spectator).players;
        },
        color() {
            if (this.playerView !== undefined)
                return this.playerView.thisPlayer.color;
            if (this.spectator !== undefined)
                return this.spectator.color;
            throw new Error('Neither playerView nor spectator are defined');
        },
        downloadLogUrl() {
            const id = this.playerView?.id || this.spectator?.id;
            if (id === undefined) {
                return undefined;
            }
            return `${paths_1.paths.API_GAME_LOGS}?id=${id}&full=true`;
        },
        playersInPlace() {
            const copy = [...this.viewModel.players];
            copy.sort(function (a, b) {
                if (a.victoryPointsBreakdown.total < b.victoryPointsBreakdown.total)
                    return -1;
                if (a.victoryPointsBreakdown.total > b.victoryPointsBreakdown.total)
                    return 1;
                if (a.megaCredits < b.megaCredits)
                    return -1;
                if (a.megaCredits > b.megaCredits)
                    return 1;
                return 0;
            });
            return copy.reverse();
        },
        winners() {
            const sortedPlayers = this.playersInPlace;
            const firstWinner = sortedPlayers[0];
            const winners = [firstWinner];
            for (let i = 1; i < sortedPlayers.length; i++) {
                if (sortedPlayers[i].victoryPointsBreakdown.total === firstWinner.victoryPointsBreakdown.total &&
                    sortedPlayers[i].megaCredits === firstWinner.megaCredits) {
                    winners.push(sortedPlayers[i]);
                }
            }
            return winners;
        },
        isSoloGame() {
            return this.players.length === 1;
        },
        vpDataset() {
            return this.players.map((player) => {
                return {
                    label: player.name,
                    data: player.victoryPointsByGeneration,
                    color: player.color,
                };
            });
        },
        globalsDataset() {
            const dataset = [];
            const gpg = this.game.globalsPerGeneration;
            function getValues(param, min, max) {
                return gpg.map((entry) => {
                    const val = entry[param] ?? min;
                    return 100 * (val - min) / (max - min);
                });
            }
            dataset.push({ label: 'Temperature', color: Color_1.Color.RED, data: getValues(GlobalParameter_1.GlobalParameter.TEMPERATURE, -30, 8) });
            dataset.push({ label: 'Oxygen', color: Color_1.Color.GREEN, data: getValues(GlobalParameter_1.GlobalParameter.OXYGEN, 0, 14) });
            dataset.push({ label: 'Oceans', color: Color_1.Color.BLUE, data: getValues(GlobalParameter_1.GlobalParameter.OCEANS, 0, 9) });
            if (this.game.gameOptions.venusNextExtension === true) {
                dataset.push({ label: 'Venus', color: Color_1.Color.YELLOW, data: getValues(GlobalParameter_1.GlobalParameter.VENUS, 0, 30) });
            }
            if (this.game.gameOptions.moonExpansion === true) {
                dataset.push({ label: 'L. Habitat', color: Color_1.Color.ORANGE, data: getValues(GlobalParameter_1.GlobalParameter.MOON_HABITAT_RATE, 0, 8) });
                dataset.push({ label: 'L. Mining', color: Color_1.Color.PINK, data: getValues(GlobalParameter_1.GlobalParameter.MOON_MINING_RATE, 0, 8) });
                dataset.push({ label: 'L. Logistics', color: Color_1.Color.PURPLE, data: getValues(GlobalParameter_1.GlobalParameter.MOON_LOGISTICS_RATE, 0, 8) });
            }
            return dataset;
        },
    },
    data() {
        return {
            constants,
        };
    },
    components: {
        'board': Board_vue_1.default,
        'log-panel': LogPanel_vue_1.default,
        AppButton: AppButton_vue_1.default,
        MoonBoard: MoonBoard_vue_1.default,
        PlanetaryTracks: PlanetaryTracks_vue_1.default,
        VictoryPointChart: VictoryPointChart_vue_1.default,
    },
    methods: {
        getEndGamePlayerRowColorClass(color) {
            return (0, utils_1.playerColorClass)(color.toLowerCase(), 'bg_transparent');
        },
        getTimer(p) {
            return Timer_1.Timer.toString(p.timer);
        },
        getCorporationName(p) {
            const cards = p.tableau;
            const corporationCards = cards
                .filter((card) => (0, ClientCardManifest_1.getCard)(card.name)?.type === CardType_1.CardType.CORPORATION)
                .map((card) => card.name);
            return corporationCards.length === 0 ? [''] : corporationCards;
        },
    },
});
